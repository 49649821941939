import OrderDetailsView from "./OrderDetailsView";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getOrderById, leaveOrderComment} from "../../repository/orders";
import {useToast} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export default () => {
    const params = useParams();
    const [order, setOrder] = useState(null as null | any);
    const toast = useToast();
    const {t} = useTranslation();
    useEffect(() => {
        if(params.id) {
            getOrderById(params.id).then(data => {
                setOrder(data.data)
            }).catch(err => {
                toast({
                    title: t('orderFetch'),
                    description:
                        t('orderError'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            })
        }
    }, [params])

    const handleLeaveComment = (rating: number, comment: string) => {
        leaveOrderComment(order.orderId, rating, comment).then(data => {
            toast({
                title: t('orderComment'),
                description:
                    t('orderCommentSuccess'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }).catch(() =>{
            toast({
                title: t('orderComment'),
                description:
                    t('orderCommentFail'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    return <OrderDetailsView order={order} handleLeaveComment={handleLeaveComment}/>
}