import React, {useEffect} from 'react';
import {Flex, Image, Progress} from "@chakra-ui/react";
import {fetchAllLocations, resetState} from "../../reducers/locations";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate} from "react-router-dom";

export default () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    useEffect(() => {
        dispatch(resetState())
        dispatch(fetchAllLocations())
    }, [])
    useEffect(() => {
        setTimeout(() => {
            if(locationsState.data.length) {
                navigate('/location')
            }
        }, 1000)


    }, [locationsState.data])
    return <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} width={'full'} height={'calc(100vh - 150px)'}>
        <Image src={'/images/splash-screen-logo.svg'} height={'300px'} mb={'50px'}/>
        <Progress isIndeterminate width={'calc(100% - 100px)'} maxW={'500px'} colorScheme='orange'>
        </Progress>
    </Flex>
}