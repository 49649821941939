import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, Flex} from '@chakra-ui/react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Homepage} from "./containers/Homepage/Homepage";
import {Provider} from "react-redux";
import store from "./store";
import MenuPage from "./containers/MenuPage/MenuPage";
import EatingLocation from "./containers/EatingLocation/EatingLocation";
import OrderOverview from "./containers/OrderOverview/OrderOverview";
import OrderInfo from "./containers/OrderInfo/OrderInfo";
import {OrderComplete} from "./containers/OrderComplete/OrderComplete";
import './i18n';
import MenuBar from "./components/MenuBar/MenuBar";
import UserOrders from "./containers/UserOrders/UserOrders";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import theme from "./theme";
import bgImage from './bg.jpg';
import Footer from "./components/Footer/Footer";
import OrderForm from "./containers/OrderForm/OrderForm";
import OrderDetails from "./containers/OrderDetails/OrderDetails";
import SplashScreen from "./containers/SplashScreen/SplashScreen";
import './assets/fonts/IntroRust-Base.otf';
import {KeepAwake} from "react-keep-awake";
import { useIdleTimer } from 'react-idle-timer';
import {isBonTacosAccount} from "./services/auth";
import App from "./App";
import * as serviceWorker from './serviceWorker'



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <React.StrictMode>
        <App/>
  </React.StrictMode>
);
// serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
