import React from 'react'
import { Badge, Flex, Image, Text } from '@chakra-ui/react'
import {useTranslation} from "react-i18next";

export default ({
                    items,
                    handleSelectItem,
                }: {
    items: any[]
    handleSelectItem: Function
}) => {
    const {t} = useTranslation();
    return (
        <Flex
            gap={4}
            cursor={'pointer'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            w={'full'}
        >
            {items.map((item) => (
                <Flex
                    maxW={'calc(100% - 60px)'}
                    w={'full'}
                    mx={'auto'}
                    onClick={() => handleSelectItem(item)}
                    borderRadius={'5px'}
                    p={'10px'}
                    color={'white'}
                    justifyContent={'space-between'}
                    borderColor={'brand.primary'}
                    borderWidth={'1px'}
                >
                    <Flex maxW={'40%'} w={'100%'}>
                        <Image src={item.image} mx={'auto'} />
                    </Flex>
                    <Flex flexDirection={'column'} w={'full'} ml={'30px'} justifyContent={'center'}>
                        <Text as={'h1'} fontWeight={'bold'} color={'brand.primary'} mb={2}>{t(item.name)} - {item.price} {t('mkd')}</Text>
                        <Text fontSize={{base: '10px', md:'18px'}}>{item.maxNumberOfMeats}x {t('numberOfMeats')}</Text>
                        <Text fontSize={{base: '10px', md:'18px'}}>{item.maxNumberOfSauces}x {t('numberOfSauces')}</Text>
                        <Text fontSize={{base: '10px', md:'18px'}}>{t('friesOrVegetables')}</Text>
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}
