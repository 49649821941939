import { useState, useEffect, useCallback } from 'react';
import USBPrinterService, { PrinterConnection, USBDevice} from "../services/printer";

interface UseUSBPrinterReturn {
    isSupported: boolean;
    discoveredPrinters: USBDevice[];
    discoveryStatus: string;
    printerStatus: string;
    currentPrinter: PrinterConnection | null;
    findAllPrinters: () => Promise<void>;
    connectToSpecificPrinter: () => Promise<void>;
    connectToPrinter: (device: USBDevice) => Promise<void>;
    printText: (text: string, useCyrillicEncoding?: boolean) => Promise<void>;
    printOrder: (order: any, useCyrillicEncoding?: boolean) => Promise<void>;
    disconnectPrinter: () => Promise<void>;
    autoConnectToLastPrinter: () => Promise<boolean>;
    autoConnectToAnyPrinter: () => Promise<boolean>;
}

/**
 * React Hook for managing USB printer connections
 */
export function useUSBPrinter(): UseUSBPrinterReturn {
    // Get singleton instance of printer service
    const printerService = USBPrinterService.getInstance();

    // State
    const [discoveredPrinters, setDiscoveredPrinters] = useState<USBDevice[]>([]);
    const [discoveryStatus, setDiscoveryStatus] = useState<string>('Status will appear here...');
    const [printerStatus, setPrinterStatus] = useState<string>('No printer connected.');
    const [currentPrinter, setCurrentPrinter] = useState<PrinterConnection | null>(null);
    const [isSupported, setIsSupported] = useState<boolean>(true);

    // Check if WebUSB is supported
    useEffect(() => {
        const supported = printerService.isSupported();
        setIsSupported(supported);

        if (!supported) {
            setDiscoveryStatus('Web USB is not supported in this browser.');
        }
    }, []);

    // Check for auto-connection on component mount
    useEffect(() => {
        // Wait a short delay to allow the UI to render first
        const timer = setTimeout(() => {
            if (isSupported && !currentPrinter) {
                autoConnectToLastPrinter();
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [isSupported]);

    // Find all previously authorized printers
    const findAllPrinters = useCallback(async (): Promise<void> => {
        if (!isSupported) {
            setDiscoveryStatus('Web USB is not supported in this browser.');
            return;
        }

        setDiscoveryStatus('Requesting USB devices...');
        setDiscoveredPrinters([]);

        try {
            // Get all previously authorized devices
            const printers = await printerService.getAuthorizedPrinters();
            setDiscoveredPrinters(printers);

            const printerCount = printers.length;
            setDiscoveryStatus(`Found ${printerCount} printer${printerCount !== 1 ? 's' : ''}.`);

            if (printerCount === 0) {
                setDiscoveryStatus(prev => prev + ' No printers found. You may need to connect a printer and select it specifically.');
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setDiscoveryStatus(`Error: ${errorMessage}`);
            console.error('USB Discovery error:', error);
        }
    }, [isSupported]);

    // Connect to a specific printer via browser dialog
    const connectToSpecificPrinter = useCallback(async (): Promise<void> => {
        if (!isSupported) {
            setDiscoveryStatus('Web USB is not supported in this browser.');
            return;
        }

        setDiscoveryStatus('Requesting USB printer...');

        try {
            // Request a USB printer specifically via the service
            const device = await printerService.requestPrinterSelection();
            setDiscoveryStatus(`Selected printer: ${device.productName || 'Unknown device'}`);

            // Connect to the selected printer
            await connectToPrinter(device);
        } catch (error) {
            if (error instanceof Error && error.name === 'NotFoundError') {
                setDiscoveryStatus('No printer was selected.');
            } else {
                const errorMessage = error instanceof Error ? error.message : 'Unknown error';
                setDiscoveryStatus(`Error: ${errorMessage}`);
                console.error('USB Connection error:', error);
            }
        }
    }, [isSupported]);

    // Connect to a specific printer device
    const connectToPrinter = useCallback(async (device: USBDevice): Promise<void> => {
        setPrinterStatus(`Connecting to ${device.productName || 'printer'}...`);

        try {
            // Connect to the printer using the service
            const printerConnection = await printerService.connectToPrinter(device);
            setCurrentPrinter(printerConnection);

            // Update status with printer details
            setPrinterStatus(`Connected to ${device.productName || 'printer'}.
Product ID: 0x${device.productId.toString(16)}
Vendor ID: 0x${device.vendorId.toString(16)}
Interface: ${printerConnection.interfaceNumber}
Ready to print!`);

        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setPrinterStatus(`Connection error: ${errorMessage}`);
            console.error('USB Connection error:', error);
        }
    }, []);

    // Auto-connect to last used printer
    const autoConnectToLastPrinter = useCallback(async (): Promise<boolean> => {
        if (!isSupported) {
            setDiscoveryStatus('Web USB is not supported in this browser.');
            return false;
        }

        try {
            setPrinterStatus('Attempting to auto-connect to last used printer...');
            const connection = await printerService.autoConnectToLastPrinter();

            if (connection) {
                setCurrentPrinter(connection);
                const device = connection.device;
                setPrinterStatus(`Connected to ${device.productName || 'printer'}.
Product ID: 0x${device.productId.toString(16)}
Vendor ID: 0x${device.vendorId.toString(16)}
Interface: ${connection.interfaceNumber}
Ready to print!`);
                return true;
            } else {
                setPrinterStatus('Could not auto-connect to previous printer. Please connect manually.');
                return false;
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setPrinterStatus(`Auto-connect error: ${errorMessage}`);
            console.error('Auto-connect error:', error);
            return false;
        }
    }, [isSupported]);

    // Auto-connect to any available printer
    const autoConnectToAnyPrinter = useCallback(async (): Promise<boolean> => {
        if (!isSupported) {
            setDiscoveryStatus('Web USB is not supported in this browser.');
            return false;
        }

        try {
            setPrinterStatus('Attempting to auto-connect to any available printer...');
            const connection = await printerService.autoConnectToAnyPrinter();

            if (connection) {
                setCurrentPrinter(connection);
                const device = connection.device;
                setPrinterStatus(`Connected to ${device.productName || 'printer'}.
Product ID: 0x${device.productId.toString(16)}
Vendor ID: 0x${device.vendorId.toString(16)}
Interface: ${connection.interfaceNumber}
Ready to print!`);
                return true;
            } else {
                setPrinterStatus('No available printers found. Please connect manually.');
                return false;
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setPrinterStatus(`Auto-connect error: ${errorMessage}`);
            console.error('Auto-connect error:', error);
            return false;
        }
    }, [isSupported]);

    // Print text to printer
    const printText = useCallback(async (text: string, useCyrillicEncoding: boolean = true): Promise<void> => {
        if (!currentPrinter) {
            setPrinterStatus('No printer connected. Please connect first.');
            return;
        }

        try {
            setPrinterStatus('Preparing to print...');
            await printerService.printText(text, useCyrillicEncoding);
            setPrinterStatus('Document sent to printer!');
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setPrinterStatus(`Print error: ${errorMessage}`);
            console.error('Print error:', error);
        }
    }, [currentPrinter]);

    // Print order receipt
    const printOrder = useCallback(async (order: any, useCyrillicEncoding: boolean = true): Promise<void> => {
        if (!currentPrinter) {
            setPrinterStatus('No printer connected. Please connect first.');
            return;
        }

        try {
            setPrinterStatus('Preparing to print order...');
            await printerService.printOrder(order, useCyrillicEncoding);
            setPrinterStatus('Order receipt sent to printer!');
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setPrinterStatus(`Print error: ${errorMessage}`);
            console.error('Print error:', error);
        }
    }, [currentPrinter]);

    // Disconnect from printer
    const disconnectPrinter = useCallback(async (): Promise<void> => {
        if (!currentPrinter) {
            setPrinterStatus('No printer connected.');
            return;
        }

        try {
            await printerService.disconnectPrinter();
            setCurrentPrinter(null);
            setPrinterStatus('Printer disconnected.');
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            setPrinterStatus(`Disconnect error: ${errorMessage}`);
            console.error('Disconnect error:', error);
        }
    }, [currentPrinter]);

    // Return all hook properties and functions
    return {
        isSupported,
        discoveredPrinters,
        discoveryStatus,
        printerStatus,
        currentPrinter,
        findAllPrinters,
        connectToSpecificPrinter,
        connectToPrinter,
        printText,
        printOrder,
        disconnectPrinter,
        autoConnectToLastPrinter,
        autoConnectToAnyPrinter
    };
}

export default useUSBPrinter;