// usbPrinterService.ts
export interface USBEndpoint {
    endpointNumber: number;
    direction: 'in' | 'out';
    type: string;
}

export interface USBAlternateInterface {
    interfaceClass: number;
    interfaceSubclass: number;
    interfaceProtocol: number;
    endpoints: USBEndpoint[];
}

export interface USBInterface {
    interfaceNumber: number;
    alternate: USBAlternateInterface;
}

export interface USBConfiguration {
    configurationValue: number;
    interfaces: USBInterface[];
}

export interface USBDevice {
    deviceClass: number;
    deviceSubclass: number;
    deviceProtocol: number;
    productId: number;
    vendorId: number;
    productName?: string;
    manufacturerName?: string;
    serialNumber?: string;
    configuration: USBConfiguration | null;
    configurations: USBConfiguration[];
    opened: boolean;

    open(): Promise<void>;
    close(): Promise<void>;
    selectConfiguration(configurationValue: number): Promise<void>;
    claimInterface(interfaceNumber: number): Promise<void>;
    releaseInterface(interfaceNumber: number): Promise<void>;
    transferOut(endpointNumber: number, data: BufferSource): Promise<USBOutTransferResult>;
}

export interface USBOutTransferResult {
    bytesWritten: number;
    status: 'ok' | 'stall' | 'babble';
}

export interface WebUSB {
    getDevices(): Promise<USBDevice[]>;
    requestDevice(options: { filters: Array<USBDeviceFilter> }): Promise<USBDevice>;
}

export interface USBDeviceFilter {
    vendorId?: number;
    productId?: number;
    classCode?: number;
    subclassCode?: number;
    protocolCode?: number;
    serialNumber?: string;
}

// Extend Navigator interface
declare global {
    interface Navigator {
        usb?: WebUSB;
    }
}

/**
 * Interface representing a USB printer connection
 */
export interface PrinterConnection {
    device: USBDevice;
    interfaceNumber: number;
}

/**
 * USB Printer Service - Handles WebUSB connections to printers
 */
// usbPrinterService.ts

/**
 * Interface representing a USB printer connection
 */
export interface PrinterConnection {
    device: USBDevice;
    interfaceNumber: number;
}


/**
 * USB Printer Service - Handles WebUSB connections to printers
 */
export interface PrinterConnection {
    device: USBDevice;
    interfaceNumber: number;
}

/**
 * USB Printer Service - Handles WebUSB connections to printers
 */
class USBPrinterService {
    private static instance: USBPrinterService;
    private currentPrinter: PrinterConnection | null = null;
    private PRINTER_CLASS = 0x07; // USB Printer class code

    // Local storage key for storing the last connected printer
    private readonly LAST_PRINTER_KEY = 'usb_printer_service_last_printer';

    // Common printer vendor IDs (not exhaustive)
    private printerVendorIds = [
        0x04b8, // Epson
        0x04f9, // Brother
        0x03f0, // HP
        0x067b, // Prolific (many receipt printers)
        0x0483, // STMicroelectronics (many thermal printers)
        0x0dd4, // Custom printers
        0x07cf  // Casio
    ];

    // Private constructor for singleton pattern
    private constructor() {
        // Check if WebUSB is supported
        this.isSupported();

        // Try to auto-connect to the last used printer (if any)
        if (typeof window !== 'undefined') {
            // Use setTimeout to ensure this runs after construction in a browser environment
            setTimeout(() => this.autoConnectToLastPrinter(), 0);
        }
    }

    /**
     * Get the singleton instance of the service
     */
    public static getInstance(): USBPrinterService {
        if (!USBPrinterService.instance) {
            USBPrinterService.instance = new USBPrinterService();
        }
        return USBPrinterService.instance;
    }

    /**
     * Check if WebUSB is supported in the current browser
     */
    public isSupported(): boolean {
        return typeof navigator !== 'undefined' && !!navigator.usb;
    }

    /**
     * Checks if a USB device is likely a printer
     */
    private deviceIsPrinter(device: USBDevice): boolean {
        // Check device class if available
        if (device.deviceClass === this.PRINTER_CLASS) {
            return true;
        }

        // Check interfaces for printer class
        if (device.configuration) {
            for (const intf of device.configuration.interfaces) {
                if (intf.alternate.interfaceClass === this.PRINTER_CLASS) {
                    return true;
                }
            }
        }

        // Check against common printer vendor IDs
        return this.printerVendorIds.includes(device.vendorId);
    }

    /**
     * Find a suitable printer interface
     */
    private findPrinterInterface(device: USBDevice): number {
        if (!device.configuration) return -1;

        // First look for printer class interfaces
        for (let i = 0; i < device.configuration.interfaces.length; i++) {
            const intf = device.configuration.interfaces[i];
            if (intf.alternate.interfaceClass === this.PRINTER_CLASS) {
                return i;
            }
        }

        // If no printer class interfaces, try the first interface with out endpoints
        for (let i = 0; i < device.configuration.interfaces.length; i++) {
            const intf = device.configuration.interfaces[i];
            if (this.findOutEndpoint(intf)) {
                return i;
            }
        }

        // If all else fails, try interface 0
        if (device.configuration.interfaces.length > 0) {
            return 0;
        }

        return -1;
    }

    /**
     * Find an output endpoint for sending data
     */
    private findOutEndpoint(interface_: USBInterface): USBEndpoint | undefined {
        return interface_.alternate.endpoints.find(ep => ep.direction === 'out');
    }

    /**
     * Convert string to byte array for sending to printer
     */
    private stringToByteArray(str: string): number[] {
        const bytes: number[] = [];
        for (let i = 0; i < str.length; i++) {
            bytes.push(str.charCodeAt(i));
        }
        return bytes;
    }

    /**
     * Handles Cyrillic text for Zijang printers that don't support it
     * @param text The input text to process
     * @returns Either an array of bytes for direct printing or null if transliteration should be used
     */
    private handleCyrillicText(text: string): number[] | null {
        // For Zijang printers, it seems direct Cyrillic encoding doesn't work well
        // Instead, let's transliterate Cyrillic to Latin characters
        const transliterate = (text: string): string => {
            // Mapping from Cyrillic to Latin characters
            const cyrillicToLatin: Record<string, string> = {
                'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
                'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
                'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
                'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch',
                'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya',
                'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'YO',
                'Ж': 'ZH', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
                'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
                'Ф': 'F', 'Х': 'KH', 'Ц': 'TS', 'Ч': 'CH', 'Ш': 'SH', 'Щ': 'SCH',
                'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'YU', 'Я': 'YA'
            };

            return text.split('').map(char => cyrillicToLatin[char] || char).join('');
        };

        // Convert the text to ASCII bytes using transliteration
        return this.stringToByteArray(transliterate(text));
    }

    /**
     * Get all authorized USB printers
     * @returns Promise resolving to array of printer devices
     */
    public async getAuthorizedPrinters(): Promise<USBDevice[]> {
        if (!this.isSupported()) {
            throw new Error('WebUSB is not supported in this browser');
        }

        // Get all previously authorized devices
        const devices = await navigator?.usb?.getDevices() || [];

        // Filter for potential printers
        return devices.filter(device => this.deviceIsPrinter(device));
    }

    /**
     * Request user to select a specific printer
     * @returns Promise resolving to the selected device
     */
    public async requestPrinterSelection(): Promise<USBDevice> {
        if (!this.isSupported()) {
            throw new Error('WebUSB is not supported in this browser');
        }

        // Request a USB printer specifically
        // @ts-ignore
        return await navigator.usb.requestDevice({
            filters: [
                { classCode: this.PRINTER_CLASS } // USB printer class
            ]
        });
    }

    /**
     * Auto-connect to the last used printer if available
     * @returns Promise resolving to the printer connection or null if not found/connected
     */
    public async autoConnectToLastPrinter(): Promise<PrinterConnection | null> {
        try {
            // Check if we have a stored printer
            const storedPrinterStr = localStorage.getItem(this.LAST_PRINTER_KEY);
            if (!storedPrinterStr) {
                console.log('No previously connected printer found');
                return null;
            }

            // Parse the stored printer info
            const storedPrinter = JSON.parse(storedPrinterStr);

            // Get all authorized devices
            const devices = await navigator?.usb?.getDevices() || [];

            // Try to find the stored printer in the authorized devices
            const matchedDevice = devices.find(device =>
                device.vendorId === storedPrinter.vendorId &&
                device.productId === storedPrinter.productId
            );

            if (!matchedDevice) {
                console.log('Previously connected printer not found among authorized devices');
                return null;
            }

            // Connect to the matched device
            console.log('Auto-connecting to previously used printer:', matchedDevice.productName);
            return await this.connectToPrinter(matchedDevice);
        } catch (error) {
            console.error('Error auto-connecting to last printer:', error);
            return null;
        }
    }

    /**
     * Auto-connect to any available printer
     * @returns Promise resolving to the printer connection or null if not found/connected
     */
    public async autoConnectToAnyPrinter(): Promise<PrinterConnection | null> {
        try {
            // Get all authorized printers
            const printers = await this.getAuthorizedPrinters();

            if (printers.length === 0) {
                console.log('No authorized printers found');
                return null;
            }

            // Connect to the first available printer
            console.log('Auto-connecting to first available printer:', printers[0].productName);
            return await this.connectToPrinter(printers[0]);
        } catch (error) {
            console.error('Error auto-connecting to any printer:', error);
            return null;
        }
    }

    /**
     * Connect to a specific USB printer
     * @param device The USB device to connect to
     * @returns Promise resolving when connection is complete
     */
    public async connectToPrinter(device: USBDevice): Promise<PrinterConnection> {
        try {
            // Check if device is already open
            try {
                // Trying to open an already-open device will throw an error
                await device.open();
            } catch (error) {
                if (error instanceof Error && error.message.includes('already open')) {
                    console.log('Device is already open, continuing with connection');
                } else {
                    // If it's another type of error, re-throw it
                    throw error;
                }
            }

            // Select configuration #1 (or the first available configuration)
            if (device.configuration === null) {
                await device.selectConfiguration(1);
            }

            // Find a suitable interface (typically interface #0 for printers)
            const interfaceNumber = this.findPrinterInterface(device);

            if (interfaceNumber === -1) {
                throw new Error('No suitable printer interface found');
            }

            // Claim the interface - wrap in try/catch to handle already claimed interfaces
            try {
                await device.claimInterface(interfaceNumber);
            } catch (error) {
                if (error instanceof Error && error.message.includes('already claimed')) {
                    console.log('Interface already claimed, continuing');
                } else {
                    throw error;
                }
            }

            // Store the connection
            this.currentPrinter = {
                device: device,
                interfaceNumber: interfaceNumber
            };

            // Store printer info for auto-reconnect
            try {
                const printerInfo = {
                    vendorId: device.vendorId,
                    productId: device.productId,
                    name: device.productName
                };
                localStorage.setItem(this.LAST_PRINTER_KEY, JSON.stringify(printerInfo));
            } catch (e) {
                console.warn('Could not save printer info to localStorage:', e);
            }

            return this.currentPrinter;
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            throw new Error(`Connection error: ${errorMessage}`);
        }
    }

    /**
     * Get the currently connected printer
     */
    public getCurrentPrinter(): PrinterConnection | null {
        return this.currentPrinter;
    }

    /**
     * Print text to the connected printer
     * @param text The text to print
     * @param useCyrillicEncoding Whether to use special encoding for Cyrillic text
     */
    public async printText(text: string, useCyrillicEncoding: boolean = true): Promise<void> {
        if (!this.currentPrinter) {
            throw new Error('No printer connected');
        }

        try {
            // Find the output endpoint
            const device = this.currentPrinter.device;
            const interfaceNumber = this.currentPrinter.interfaceNumber;

            if (!device.configuration) {
                throw new Error('Device configuration is null');
            }

            const interface_ = device.configuration.interfaces[interfaceNumber];
            const endpoint = this.findOutEndpoint(interface_);

            if (!endpoint) {
                throw new Error('Could not find output endpoint');
            }

            // For Zijang printers, we'll use standard ASCII encoding
            // as they typically don't handle CP866 well
            const initCommands = [
                0x1B, 0x40,         // ESC @ - Initialize printer
                0x1B, 0x74, 0x00,   // ESC t 0 - Character code table: PC437 (Standard ASCII)
                0x1B, 0x21, 0x00,   // ESC ! 0 - Normal text mode
                0x1B, 0x61, 0x00    // ESC a 0 - Left align
            ];

            const feedAndCutCommands = [
                0x1B, 0x64, 0x05,   // ESC d 5 - Feed 5 lines
                0x1D, 0x56, 0x00    // GS V 0 - Cut paper (partial cut)
            ];

            // Process text data based on encoding option
            let textBytes = this.stringToByteArray(text) || [];

            // Create a combined data array
            const data = new Uint8Array([
                ...initCommands,
                ...textBytes,
                ...feedAndCutCommands
            ]);

            // Send data to the printer
            await device.transferOut(endpoint.endpointNumber, data);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            throw new Error(`Print error: ${errorMessage}`);
        }
    }

    /**
     * Print a receipt with order details
     * @param order Order details object
     * @param useCyrillicEncoding Whether to use special encoding for Cyrillic text
     */
    public async printOrder(order: {
        orderName: string,
        orderId: string,
        orderPlace: string,
        createdAt: string | Date,
        totalPrice: string | number
    }, useCyrillicEncoding: boolean = true): Promise<void> {
        // Format dates with fallback for different date formats
        const formatDate = (date: string | Date) => {
            try {
                const dateObj = typeof date === 'string' ? new Date(date) : date;
                return `${dateObj.getDate().toString().padStart(2, '0')}.${(dateObj.getMonth() + 1).toString().padStart(2, '0')}.${dateObj.getFullYear()} - ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
            } catch (e) {
                return date.toString();
            }
        };

        // Generate order receipt text - note we're using standard ESC/POS codes
        // but the actual text encoding will be handled by the printText method
        const receiptText =
            `\x1B\x21\x08Name: ${order.orderName}\x1B\x21\x00
\x1B\x21\x08Order ID: ${order.orderId?.split('-').pop()?.slice(6) || order.orderId}\x1B\x21\x00
\x1B\x21\x08Location: ${order.orderPlace}\x1B\x21\x00
\x1B\x21\x08Date: ${formatDate(order.createdAt)}\x1B\x21\x00
\x1B\x21\x08Price: ${order.totalPrice}\x1B\x21\x00

\x1B\x61\x01Your order is being prepared. 
Please proceed to the checkout to 
complete your payment.
Thank you!

Vashata narachka se podgotvuva. 
Ve molime prodolzhete kon kasata
za da ja izvrshite vashata naplata.
Vi blagodarime!
\x1B\x61\x00

`;

        // Send to printer with the specified encoding option
        await this.printText(receiptText, useCyrillicEncoding);
    }

    /**
     * Disconnect from the current printer
     */
    public async disconnectPrinter(): Promise<void> {
        if (!this.currentPrinter) {
            return;
        }

        try {
            const device = this.currentPrinter.device;
            await device.releaseInterface(this.currentPrinter.interfaceNumber);
            await device.close();
            this.currentPrinter = null;
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            throw new Error(`Disconnect error: ${errorMessage}`);
        }
    }
}

export default USBPrinterService;