import React, {useEffect, useState} from "react";
import {
    useDisclosure,
    Collapse,
    Box,
    Button,
    ModalHeader,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Text,
    FormControl, FormLabel, Input, FormErrorMessage, Flex, Spinner, HStack, PinInput, PinInputField, useToast
} from "@chakra-ui/react";
import {Controller, useForm} from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import 'react-phone-number-input/style.css'
import {isValidPhoneNumber} from "react-phone-number-input";
import {useAppDispatch} from "../../hooks";
import {activateAppUserAction, createAppUserAction} from "./registrationSlice";
import {useTranslation} from "react-i18next";

export default ({isOpenFlag, setIsOpenFlag, error}:{isOpenFlag: boolean, setIsOpenFlag: Function, error: Boolean}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [stepCount, setStepCount] = useState(0)
    const dispatch = useAppDispatch();
    const toast = useToast()
    const {t} = useTranslation()
    useEffect(() => {
        if(isOpenFlag) {
            onOpen()
        } else {
            onClose();
        }
    }, [isOpenFlag])

    const {register, getValues, control, formState: {errors}, handleSubmit} = useForm();
    const {register : registerOTP, setValue: setValueOTP, getValues: getValuesOTP, control: controlOTP, formState: {errors: errorsOTP}, handleSubmit: handleSubmitOTP} = useForm({
        defaultValues: {
            'phone': '',
            'OTP': '',
        }
    });

    const handleCloseModal = () => {
        setIsOpenFlag(false);
        onClose()
    }
    const handleRegisterDetailsSubmit = async (data: any) => {
        setStepCount(-1);
        try {
            await dispatch(createAppUserAction({
                ...data,
                confirmEmail: data.email,
                confirmPassword: data.password
            })).unwrap()
            setValueOTP('phone', data.phone)
            setStepCount(1);

        } catch (e) {
            setStepCount(0)
            toast({
                title: t('registrationFail'),
                status: "error"
            })
        }
    }
    const handleValidateOTPSubmit = async (data: any) => {
        setStepCount(-1);
        try {
            await dispatch(activateAppUserAction(data)).unwrap()
            setStepCount(2)
        } catch (e) {
            toast({
                title: t('activationFail'),
                status: "error"
            })
            setStepCount(1)
        }
    }
    return <Modal onClose={handleCloseModal} isOpen={isOpen} isCentered size={{lg: 'md', base: 'full'}} motionPreset={'slideInBottom'}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Register</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Collapse in={stepCount === -1} animateOpacity>
                    <Spinner size={'lg'} position={'relative'} left={'50%'}/>
                </Collapse>
                {stepCount === 0 && <Collapse in={stepCount === 0} animateOpacity>
                    <Box
                        p='40px'
                        mt='4'
                        rounded='md'
                    >
                        <form onSubmit={handleSubmit(handleRegisterDetailsSubmit)}>
                            <FormControl isInvalid={!!errors.email} mb={'10px'}>
                                <FormLabel>
                                    Email<span>*</span>
                                </FormLabel>
                                <Input
                                    type={'email'}
                                    {...register('email', { required: true })}
                                />
                                {!!errors.email && (
                                    <FormErrorMessage>
                                        Email is required.
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!errors.displayName} mb={'10px'}>
                                <FormLabel>
                                    Name<span>*</span>
                                </FormLabel>
                                <Input
                                    {...register('displayName', { required: true })}
                                />
                                {!!errors.displayName && (
                                    <FormErrorMessage>
                                        Name is required.
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!errors.password} mb={'10px'}>
                                <FormLabel>
                                    Password<span>*</span>
                                </FormLabel>
                                <Input
                                    {...register('password', { required: true })}
                                />
                                {!!errors.password && (
                                    <FormErrorMessage>
                                        Password is required.
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!errors.confirmPassword} mb={'10px'}>
                                <FormLabel>
                                    Confirm Password<span>*</span>
                                </FormLabel>
                                <Input

                                    {...register('confirmPassword', { required: true,
                                        validate:(confirmPassword) => getValues('password') === confirmPassword ? true : 'passwordsMatch' })}
                                />
                                {!!errors.confirmPassword && (
                                    <FormErrorMessage>
                                        {errors.confirmPassword?.type === 'required' ? 'required' : errors?.confirmPassword?.message?.toString()}

                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!errors.phone} mb={'10px'}>
                                <FormLabel>
                                    Phone<span>*</span>
                                </FormLabel>
                                <PhoneInput
                                    control={control}
                                    name={'phone'}
                                    required={true}
                                    country={'MK'}
                                    rules={{
                                        validate: (num:any) => isValidPhoneNumber(num?.toString() || '')
                                    }}
                                    defaultCountry={"MK"}
                                    useNationalFormatForDefaultCountryValue={true}
                                    inputComponent={Input}
                                />
                                {/*<Input*/}
                                {/*    {...register('phone', { required: true })}*/}
                                {/*/>*/}
                                {!!errors.phone && (
                                    <FormErrorMessage>
                                        Phone is required.
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            {stepCount === 0 && <Flex justifyContent={'flex-end'}><Button type={"submit"}>Next</Button></Flex>}
                        </form>
                    </Box>
                </Collapse>}
                {stepCount === 1 && <Collapse in={stepCount === 1} animateOpacity key={stepCount}>
                    <Box
                        p='40px'
                        mt='4'
                        rounded='md'
                    >
                        <form>
                            <Text align={'center'} mb={10}>Please enter your activation code sent via SMS</Text>
                            <Controller control={controlOTP} render={({ field: { onChange, onBlur, value } }) => <HStack justifyContent={'center'}>
                                <PinInput size='lg'  onComplete={(data: string)=> {setValueOTP('OTP', data); handleSubmitOTP(handleValidateOTPSubmit)()}}>
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>} name={'OTP'}/>
                        </form>
                    </Box>
                </Collapse> }
                <Collapse in={stepCount === 2} animateOpacity>
                    <Box
                        p='40px'
                        mt='4'
                        rounded='md'
                    >
                        Registration successful! Please log in!
                    </Box>
                </Collapse>
            </ModalBody>
            <ModalFooter>


            </ModalFooter>
        </ModalContent>
    </Modal>
}