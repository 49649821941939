import {Box, Button, Flex, Switch, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {getLanguage, isBonTacosAccount, setLanguage} from "../../services/auth";
import {changeLanguage} from "i18next";
import useUSBPrinter from "../../hooks/useUSBPrinter";
import useHIDPrinter from "../../hooks/useHIDPrinter";
export enum LANGUAGES {
    EN= 'en',
    MK = 'mk'
}
export default () => {

    const {
        isSupported,
        discoveredPrinters,
        discoveryStatus,
        printerStatus,
        currentPrinter,
        findAllPrinters,
        connectToSpecificPrinter,
        connectToPrinter,
        printText,
        printOrder,
        disconnectPrinter,
        autoConnectToAnyPrinter
    } = useUSBPrinter();
    const [printerDetailsShown, setPrinterDetailsShown] = useState(false)

    const sampleOrder: any = {
        orderName: 'John Doe',
        orderId: 'ORD-123456-789',
        orderPlace: 'Table 5',
        createdAt: new Date().toISOString(),
        totalPrice: '$25.99'
    };

    // Handle printing sample order
    const handlePrintOrder = () => {
        printOrder(sampleOrder);
    };

    return <Box p={5} color={'white'} marginTop={'auto'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            <span onDoubleClick={() => {setPrinterDetailsShown(!printerDetailsShown)}}>Copyright {new Date().getFullYear()}</span>
            {printerDetailsShown && <div className="printer-container">
                <Button onClick={() => { document?.querySelector("body")?.requestFullscreen().then(() => {
                     const body = document.getElementById("root");
                     if(body) {
                         body.style.overflow = 'auto';
                     }
                }) }}>Fullscreen</Button>
                <h2>USB Printer Connection</h2>

                <div className="printer-status">
                    <h3>Status</h3>
                    <pre>{printerStatus}</pre>
                </div>

                <div className="printer-discovery">
                    <h3>Printer Discovery</h3>
                    <div>{discoveryStatus}</div>

                    <div className="button-group">
                        <Button onClick={findAllPrinters}>Find Authorized Printers</Button>
                        <Button onClick={connectToSpecificPrinter}>Connect to Printer</Button>
                        {currentPrinter && (
                            <Button onClick={disconnectPrinter}>Disconnect Printer</Button>
                        )}
                    </div>

                    {discoveredPrinters.length > 0 && (
                        <div className="printer-list">
                            <h4>Available Printers:</h4>
                            <ul>
                                {discoveredPrinters.map((device: any, index: number) => (
                                    <li key={index}>
                                        {device.productName || `Unknown Device (${device.vendorId.toString(16)})`}
                                        <Button onClick={() => connectToPrinter(device)}>Connect</Button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <div className="printer-actions">
                    <h3>Print Test</h3>
                    <div className="button-group">
                        <Button onClick={handlePrintOrder}>Print Sample Order</Button>
                    </div>
                </div>
            </div>}
        </Flex>

    </Box>
}