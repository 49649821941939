import React, {useEffect, useState} from "react";
import {useIdleTimer} from "react-idle-timer";
import theme from "./theme";
import {Provider} from "react-redux";
import store from "./store";
import {isBonTacosAccount} from "./services/auth";
import {KeepAwake} from "react-keep-awake";
import {
    Button,
    ChakraProvider,
    Flex,
    Image,
    Modal, ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import MenuBar from "./components/MenuBar/MenuBar";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import UserOrders from "./containers/UserOrders/UserOrders";
import OrderDetails from "./containers/OrderDetails/OrderDetails";
import OrderForm from "./containers/OrderForm/OrderForm";
import SplashScreen from "./containers/SplashScreen/SplashScreen";
import {Homepage} from "./containers/Homepage/Homepage";
import {useTranslation} from "react-i18next";

const timeout = 100_000
const promptBeforeIdle = 10_000
const router = createBrowserRouter([
    {
        path: '/profile',
        element: <ProfilePage/>
    },
    {
        path: '/orders',
        element: <UserOrders/>
    },
    {
        path: '/orders/:id',
        element: <OrderDetails/>
    },
    {
        path: '/location/:id/menu',
        element: <OrderForm/>
    },

    // {
    //     path: ':id/menu',
    //     element: <OrderForm/>
    // },
    // {
    //     path: ':id/order-overview',
    //     element: <OrderOverview/>
    // },
    // {
    //     path: ':id/order-info',
    //     element: <OrderInfo/>
    // },
    // {
    //     path: ':id/order-complete',
    //     element: <OrderComplete/>
    // },
    {
        path: "/",
        element: <SplashScreen/>
    },
    {
        path: "/location/:id?",
        element: <Homepage/>,
    }

]);
const App = () => {
    const [state, setState] = useState<string>('Active')
    const [remaining, setRemaining] = useState<number>(timeout)
    const [open, setOpen] = useState<boolean>(false)
    const {t} = useTranslation();

    const onIdle = () => {
        setState('Idle')
        setOpen(false)
    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
        setOpen(false);
    }


    return <ChakraProvider theme={theme}>
        <Provider store={store}>
            {isBonTacosAccount() && <KeepAwake/>}
            {isBonTacosAccount() && state === 'Idle' ? <video autoPlay muted loop={true} style={{objectFit: 'cover', position: 'fixed', top: 0, left:0, width: '100vw', height: '100vh'}}>
                <source src="/video/animation.mp4" type="video/mp4"/>
            </video>: <Flex direction={'column'} h={'100vh'}/*bgImage={bgImage}*/>
                <MenuBar/>
                <Flex mx={'auto'} w={'100%'}>
                    <RouterProvider router={router} />
                </Flex>
                <Footer/>
            </Flex>}
            {isBonTacosAccount() && <Modal isOpen={open} onClose={()=>{}}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Text mb={10}>{t('areYouActive')}</Text>
                            <Button onClick={handleStillHere} bgColor={'brand.primary'}>{t('yes')}</Button>
                        </Flex>

                    </ModalBody>
                </ModalContent>
                </Modal>}


        </Provider>
    </ChakraProvider>

}

export default App;