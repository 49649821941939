import {
    Avatar, Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Flex, FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";

export default ({displayName, email, phone, handleChangeOfPassword, handleChangeOfDisplayName}:{displayName: string, email: string, phone: string,handleChangeOfPassword: Function, handleChangeOfDisplayName: Function}) => {
    const displayNameForm = useForm({
        defaultValues: {
            displayName,
        }
    });
    useEffect(() => {
        displayNameForm.setValue('displayName', displayName)
    }, [displayName])
    const passwordForm = useForm()
    const {t} = useTranslation();
    return <Flex w={'full'} justifyContent={'center'} alignItems={'center'}>
        <Card maxW={'500px'} w={'100vw'} mt={20} display={'block'} mx={'auto'} bg={'transparent'} color={'white'}>
            <CardHeader>
                <Flex alignItems={'center'}>
                    <Avatar/> <Text as={'h1'} ml={5}><b>{t('profileInfo')}</b></Text>
                </Flex>

            </CardHeader>
            <CardBody>
                <form onSubmit={displayNameForm.handleSubmit((data) => {
                    handleChangeOfDisplayName(data.displayName)
                })}>
                    <Flex py={5} direction={'column'}>
                        <FormControl mb={'10px'}>

                            <FormLabel>
                                {t('email')}<span>*</span>
                            </FormLabel>
                            <Input disabled={true} value={email}/>
                        </FormControl>
                        <FormControl mb={'10px'}>

                            <FormLabel>
                                {t('phone')}<span>*</span>
                            </FormLabel>
                            <Input disabled={true} value={phone}/>
                        </FormControl>
                        <FormControl mb={'10px'}>

                            <FormLabel>
                                {t('displayName')}<span>*</span>
                            </FormLabel>
                            <Input
                                {...displayNameForm.register('displayName', {required: true})}
                            />
                        </FormControl>
                        <Button type={'submit'} bgColor={'brand.primary'} color={'brand.text'}>
                            {t('changeName')}
                        </Button>
                    </Flex>
                </form>
                <Divider/>
                <form onSubmit={passwordForm.handleSubmit((data) => {
                    handleChangeOfPassword(data.password)
                })}>
                    <Flex direction={'column'} py={5}>

                        {/*<FormControl isInvalid={!!passwordForm.formState.errors.password} mb={'10px'}>*/}

                        {/*    <FormLabel>*/}
                        {/*        {t('password')}<span>*</span>*/}
                        {/*    </FormLabel>*/}
                        {/*    <Input*/}
                        {/*        type={'password'}*/}
                        {/*        {...passwordForm.register('password', {required: true})}*/}
                        {/*    />*/}
                        {/*    {!!passwordForm.formState.errors.password && (*/}
                        {/*        <FormErrorMessage>*/}
                        {/*            {t('requiredField')}*/}
                        {/*        </FormErrorMessage>*/}
                        {/*    )}*/}
                        {/*</FormControl>*/}
                        {/*<FormControl isInvalid={!!passwordForm.formState.errors.confirmPassword} mb={'10px'}>*/}

                        {/*    <FormLabel>*/}
                        {/*        {t('confirmPassword')}<span>*</span>*/}
                        {/*    </FormLabel>*/}
                        {/*    <Input*/}
                        {/*        type={'password'}*/}
                        {/*        {...passwordForm.register('confirmPassword', {*/}
                        {/*            required: true,*/}
                        {/*            validate: (pass: string) => pass === passwordForm.getValues('password')*/}
                        {/*        })}*/}
                        {/*    />*/}
                        {/*    {!!passwordForm.formState.errors.confirmPassword && (*/}
                        {/*        <FormErrorMessage>*/}
                        {/*            {t('requiredField')}*/}
                        {/*        </FormErrorMessage>*/}
                        {/*    )}*/}
                        {/*</FormControl>*/}
                        {/*<Button type={'submit'} bgColor={'brand.primary'} color={'brand.text'} isDisabled={!passwordForm.formState.isValid}>*/}
                        {/*    {t('changePassword')}*/}
                        {/*</Button>*/}
                    </Flex>
                </form>
            </CardBody>
            <CardFooter>

            </CardFooter>
        </Card>
    </Flex>
}