import {
    Box,
    Flex,
    Avatar,
    HStack,
    Text,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack, Link, ButtonGroup, Image,
} from '@chakra-ui/react'

import {HamburgerIcon, CloseIcon, Icon} from '@chakra-ui/icons'
import {getLanguage, isAuth, isBonTacosAccount, logoutUser, setLanguage} from "../../services/auth";
import React, {useEffect, useState} from "react";
import {FaHome, FaUser} from "react-icons/fa";
import {FaHouse} from "react-icons/fa6";
import RegistrationSteps from "../RegistrationSteps/RegistrationSteps";
import LoginScreen from "../LoginScreen/LoginScreen";
import ForgotPasswordStart from "../ForgotPasswordStart/ForgotPasswordStart";
import {useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {changeLanguage} from "i18next";
import {useTranslation} from "react-i18next";
export enum LANGUAGES {
    EN= 'en',
    MK = 'mk'
}


interface Props {
    children: React.ReactNode
}
const NavLink = (props: Props) => {
    const { children } = props

    return (
        <Box
            as="a"
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.200', 'gray.700'),
            }}
            href={'#'}>
            {children}
        </Box>
    )
}

export default () => {
    const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false)
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loggedInState, setLoggedInState] = useState(!!isAuth());
    const registerState = useAppSelector((state: RootState) => state.register);
    const lang = getLanguage();
    const [languageSelected, setLanguageSelected] = useState(lang)
    const {t} = useTranslation();
    const handleLanguageChange = async (lang:LANGUAGES) => {
        setLanguageSelected(lang);
        setLanguage(lang);
        await changeLanguage(lang)
    }
    const logOutUser = () => {
        logoutUser();
        setLoggedInState(false);
    }
    useEffect(() => {

        setLoggedInState(!!isAuth())
    }, [isLoginModalOpen]);

    useEffect(() => {
        setLoggedInState(!!isAuth())
    }, [isAuth()]);
    return  <Box paddingTop={'15px'} mb={'30px'}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <a  href='/'><Image src={'/images/logo.png'} h={'64px'}/></a>
            <Flex alignItems={'center'}>
                <Menu>
                    <MenuButton as={Button}  color='white' bg='black' borderColor={'white'} borderWidth={'1px'}>
                        {languageSelected.toUpperCase()}
                    </MenuButton>
                    <MenuList bg={'black'}>
                        <MenuItem color='white' w={'50px'} bg='transparent' onClick={() => handleLanguageChange(LANGUAGES.EN)}>EN</MenuItem>
                        <MenuItem color='white' w={'50px'} bg='transparent'  onClick={() => handleLanguageChange(LANGUAGES.MK)}>MK</MenuItem>
                    </MenuList>
                </Menu>
                <Menu>
                    {loggedInState && !isBonTacosAccount() && <> <MenuButton mx={5}
                        as={Button}
                        rounded={'full'}
                        variant={'link'}
                        color={'white'}
                        cursor={'pointer'}
                        minW={0}>
                        <Icon as={FaUser}/>
                        {/*<Avatar*/}
                        {/*    size={'sm'}*/}
                        {/*    src={*/}
                        {/*        'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'*/}
                        {/*    }*/}
                        {/*/>*/}
                    </MenuButton>
                    <MenuList bg={'black'}>
                        <MenuItem as={'a'} bg='transparent' href={'/profile'} color={'white'}>{t('profile')}</MenuItem>
                        <MenuItem as={'a'} bg='transparent' href={'/orders'} color={'white'}>{t('orders')}</MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={logOutUser} bg='transparent' color={'white'}>{t('logout')}</MenuItem>
                    </MenuList></>}
                    {/*{!loggedInState && <ButtonGroup size='sm' isAttached variant='outline'><Button p={5} mr={3} onClick={() => {setIsLoginModalOpen(true)}}>*/}
                    {/*    Login*/}
                    {/*</Button><Button variant={'outline'} p={5}  onClick={() => {setIsRegistrationModalOpen(true)}}>*/}
                    {/*    Register*/}
                    {/*</Button></ButtonGroup>}*/}
                </Menu>
            </Flex>
        </Flex>
        <RegistrationSteps isOpenFlag={isRegistrationModalOpen} setIsOpenFlag={setIsRegistrationModalOpen} error={registerState.error}/>
        <LoginScreen isOpenFlag={isLoginModalOpen} setIsOpenFlag={setIsLoginModalOpen} openResetPassword={setIsResetPasswordModalOpen}/>
        <ForgotPasswordStart isOpenFlag={isResetPasswordModalOpen} setIsOpenFlag={setIsResetPasswordModalOpen}/>
    </Box>
}