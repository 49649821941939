import HomepageView from "./HomepageView";
import locations, {fetchAllLocations, selectLocation} from "../../reducers/locations";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {useNavigate, useParams} from "react-router-dom";
import {getAccount, isBonTacosAccount} from "../../services/auth";

export const Homepage = () => {
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const locationsState = useAppSelector((state: RootState) => state.locations);
    const navigate = useNavigate();
    const handleLocationClick = (location:any) => {
        dispatch(selectLocation(location));
        navigate(`/location/${location.locationId}/menu`)
    }
    useEffect(() => {
        if(id && locationsState.data?.length) {
            const foundLocation = locationsState.data.find(location => location.locationId === id);
            if(foundLocation) {
                handleLocationClick(foundLocation);
            }
        }
        if (!locationsState.data?.length) {
            navigate('/')
        } else {
            if(isBonTacosAccount()) {
                const account = getAccount();
                navigate(`/location/${account.location}/menu`)
            }
        }

    }, [locationsState.data])
    return <HomepageView locations={locationsState.data} handleLocationClick={handleLocationClick}/>
}