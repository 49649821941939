import {
    Button,
    Collapse,
    Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner, useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

export default ({isOpenFlag, setIsOpenFlag}:{isOpenFlag: boolean, setIsOpenFlag: Function}) => {
    const {t} = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [stepCount, setStepCount] = useState(0)
    useEffect(() => {
        if(isOpenFlag) {
            onOpen()
        } else {
            onClose();
        }
    }, [isOpenFlag])

    const {register, getValues, control, formState: {errors}, handleSubmit} = useForm();


    const handleCloseModal = () => {
        setIsOpenFlag(false);
        onClose()
    }

    const submitLoginData = (data: any) => {
        setStepCount(-1);
        setTimeout(() => {
            handleCloseModal();

        }, 2000)
    }
    return <Modal onClose={handleCloseModal} isOpen={isOpen} isCentered size={{lg: 'md', base: 'full'}} motionPreset={'slideInBottom'}>
        <ModalOverlay />
        <ModalContent>
            <form onSubmit={handleSubmit(submitLoginData)}>


                <ModalHeader>{t('resetPasswordTitle')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction={'column'}>
                        <Image src={'/images/Reset password-pana.svg'} height={'150px'}/>
                        <Collapse in={stepCount === -1} animateOpacity>
                            <Spinner size={'lg'} position={'relative'} left={'50%'}/>
                        </Collapse>
                        <Collapse in={stepCount === 0} animateOpacity>
                            <FormControl isInvalid={!!errors.email} mb={'10px'}>

                                <FormLabel>
                                    {t('email')}<span>*</span>
                                </FormLabel>
                                <Input
                                    {...register('email', { required: true })}
                                />
                                {!!errors.email && (
                                    <FormErrorMessage>
                                        {t('requiredField')}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!errors.confirmEmail} mb={'10px'}>
                                <FormLabel>
                                    {t('confirmEmail')}<span>*</span>
                                </FormLabel>
                                <Input
                                    {...register('confirmEmail', { required: true, validate: (data) => getValues('email') === data })}
                                />
                                {!!errors.confirmEmail && (
                                    <FormErrorMessage>
                                        {t('requiredField')}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        </Collapse>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button type={'submit'}>{t('resetPassword')}</Button>

                </ModalFooter>
            </form>
        </ModalContent>
    </Modal>
}